import BaseService from "../base/base.service";
import axios from "axios";

class WarehouseItemBarcodeService extends BaseService {
    get modelName() {
        return "WarehouseItemBarcode";
    }

    get({barcode, warehouseId}) {
        let params = {
            id: barcode,
            warehouseId: warehouseId
        };
        return axios.get(this.getAllUrl(), {params: params}).then(response => {
            return response.data;
        }).catch(err => {
            return err;
        });
    }
}

export default new WarehouseItemBarcodeService()
