import axios from "axios";
import BaseService from "../base/base.service";

class ClientContactService extends BaseService {
    get modelName() {
        return 'ClientContact'
    }

    getAll({clientId, query, limit, skip, sort, desc}) {
        let params = {
            client: clientId,
            q: (typeof (query) === "undefined" || query === null) ? "" : query,
            limit: limit,
            skip: skip,
            sort: (typeof (sort) === "undefined" || sort === null) ? "" : sort,
            desc: desc,
        }
        return axios.get(this.getAllUrl(), {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return Promise.reject(error)
            })
    }

    create({ ClientId, PhoneNumber }) {
        return axios.post(this.getCreateUrl(), {
            ClientId: ClientId,
            PhoneNumber: PhoneNumber
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    edit( id, { ClientId, PhoneNumber }) {
        return axios.put(this.getEditUrl(id), {
            ClientId: ClientId,
            PhoneNumber: PhoneNumber
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id))
            .then((response) => {
                return response.data
            }).catch((error) => {
                return Promise.reject(error)
        })
    }
}

export default new ClientContactService();
