import BaseService from "../base/base.service";
import axios from "axios";

class WarehouseItemService extends BaseService {
    get modelName() {
        return "WarehouseItem";
    }

    getAll({
               warehouseId,
               type,
               skip,
               limit,
               query,
               sku,
               sort,
               desc,
               name,
               barcode,
               stateeq,
               statelt,
               stategt,
               statelteq,
               stategteq,
               alert,
               categoryId,
               favourite,
               tag,
               priceCurrency,
               bulkPriceCurrency,
               purchasesPriceCurrency
           }) {
        let params = {
            warehouseId: warehouseId,
            type: type,
            q: (typeof (query) === "undefined" || query === null) ? "" : query,
            sku: (typeof (query) === "undefined" || sku === null) ? "" : sku,
            sort: (typeof (sort) === "undefined" || sort === null) ? "" : sort,
            desc: desc,
            skip: skip,
            limit: limit,
            name: (typeof (name) === "undefined" || name === null) ? "" : name,
            barcode: (typeof (barcode) === "undefined" || barcode === null) ? "" : barcode,
            stateeq: (typeof (stateeq) === "undefined" || stateeq === null) ? null : +stateeq,
            statelt: (typeof (statelt) === "undefined" || statelt === null) ? null : +statelt,
            stategt: (typeof (stategt) === "undefined" || stategt === null) ? null : +stategt,
            statelteq: (typeof (statelteq) === "undefined" || statelteq === null) ? null : +statelteq,
            stategteq: (typeof (stategteq) === "undefined" || stategteq === null) ? null : +stategteq,
            alert: (typeof (alert) === "undefined" || alert === null) ? null : !!alert,
            category: categoryId ? categoryId : null,
            tag: tag ? tag.TagId : null,
            favourite: (typeof (favourite) === "undefined" || favourite === null) ? null : favourite,
            priceCurrency: priceCurrency,
            bulkPriceCurrency: bulkPriceCurrency,
            purchasesPriceCurrency: purchasesPriceCurrency
        };
        return axios.get(this.getAllUrl(), {params: params}).then(response => {
            return response.data;
        }, function (error) {
            return Promise.reject(error);
        })
    }

    get(id) {
        return axios.get(this.getSingleUrl(id)).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    edit(WarehouseItemId, IsFavourite, LastPurchasePriceCurrencyValue) {
        let lastPurchasePriceCurrencyValue = null;

        if (LastPurchasePriceCurrencyValue && LastPurchasePriceCurrencyValue.Value) {
            lastPurchasePriceCurrencyValue = {
                Value: LastPurchasePriceCurrencyValue.Value || 0,
                Rate: LastPurchasePriceCurrencyValue.Currency ? LastPurchasePriceCurrencyValue.Currency.Rate : null,
                CurrencyId: LastPurchasePriceCurrencyValue.Currency ? LastPurchasePriceCurrencyValue.Currency.CurrencyId : null,
            };
        }

        return axios.put(this.getEditUrl(WarehouseItemId), {
            IsFavourite: IsFavourite,
            LastPurchasePriceCurrencyValue: lastPurchasePriceCurrencyValue
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id)).then((response) => {
            return response.data;
        }).catch(function (error) {
            return Promise.reject(error);
        });
    }
}

export default new WarehouseItemService()
