import axios from "axios";
import BaseService from "../base/base.service";

class SaleService extends BaseService {
    get modelName() {
        return "Sale"
    }

    getAll({
               skip,
               limit,
               query,
               sort,
               desc,
               client,
               employee,
               account,
               startDate,
               endDate,
               approved,
               debt,
               warehouse
           }) {
        let params = {
            q: (typeof (query) === "undefined" || query === null) ? "" : query,
            sort: (typeof (sort) === "undefined" || sort === null) ? "" : sort,
            desc: desc,
            skip: skip,
            limit: limit,
            client: client,
            employee: employee,
            username: account,
            start: this._getDateDDMMYYYFormat(startDate),
            end: this._getDateDDMMYYYFormat(endDate),
            approved: (typeof (approved) === "undefined" || approved === null) ? null : !!approved,
            debt: (typeof (debt) === "undefined" || debt === null) ? null : !!debt,
            warehouse: warehouse
        };
        return axios.get(this.getAllUrl(), {params: params}).then(response => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id));
    }

    edit(Sale) {
        return axios.put(this.getEditUrl(Sale.SaleId), {
            CreatedAt: Sale.CreatedAt ? this._getDateDDMMYYYFormat(new Date(Sale.CreatedAt)) : null,
            ClientId: Sale.Client.ClientId,
            WarehouseId: Sale.Warehouse.WarehouseId,
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new SaleService()
