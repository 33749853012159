import BaseService from "../base/base.service";
import axios from "axios";

class ClientService extends BaseService {
    get modelName() {
        return "Client";
    }

    getAll({
               skip,
               limit,
               query,
               sort,
               desc,
               name,
               debt,
               stateeq,
               statelt,
               stategt,
               statelteq,
               stategteq,
               lastSaleStart,
               lastSaleEnd
           }) {
        let params = {
            q: (typeof (query) === "undefined" || query === null) ? "" : query,
            sort: (typeof (sort) === "undefined" || sort === null) ? "" : sort,
            desc: desc,
            skip: skip,
            limit: limit,
            name: (typeof (name) === "undefined" || name === null) ? "" : name,
            debt: (typeof (debt) === "undefined" || debt === null) ? null : !!debt,
            stateeq: stateeq,
            statelt: statelt,
            stategt: stategt,
            statelteq: statelteq,
            stategteq: stategteq,
            lastSaleStart: this._getDateDDMMYYYFormat(lastSaleStart),
            lastSaleEnd: this._getDateDDMMYYYFormat(lastSaleEnd),
        };
        return axios.get(this.getAllUrl(), {params: params})
            .then(response => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    }

    get(id) {
        return axios.get(this.getSingleUrl(id)).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    create(client) {
        return axios.post(this.getCreateUrl(), {
            Name: client.Name,
            Details: client.Details,
            BonusPercent: client.BonusPercent
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    edit(client) {
            return axios.put(this.getEditUrl(client.ClientId), {
                Name: client.Name,
                Details: client.Details,
                BonusPercent: client.BonusPercent
            }).then((response) => {
                return response.data;
            }).catch((error) => {
                return Promise.reject(error);
            })
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id)).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new ClientService()
