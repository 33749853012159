<template>
    <imask-input
        v-model="price"
        :mask="Number"
        type="tel"
        radix="."
        thousandsSeparator=" "
        :mapToRadix="[',']"
        :scale="6"
        :unmask="true"
        :placeholder='placeholder'
        class="form-control"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        :disabled="disabled"
        @keyup.enter="$emit('keyupEnter')"
        ref="IMaskComponent"
        :min='-999999999999999'
        @contextmenu="$emit('contextmenu', $event)"
    />
</template>

<script>
import {IMaskComponent} from 'vue-imask';

export default {
    name: 'IMaskPriceInput',
    components: {
        'imask-input': IMaskComponent
    },
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        price: {
            get() {
                return (typeof (this.value) !== "undefined" && this.value !== null) ? this.value.toString() : '';
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    methods: {
        focus() {
            this.$refs.IMaskComponent.$el.focus();
        }
    },
    watch: {
        value(newV) {
            this.price = newV;
        },
    }
}
</script>
