<template>
    <b-container>
        <b-row>
            <b-form class="col-12" @submit="onSubmit" @reset="onReset" novalidate>
                <b-form-group class="my-2">
                    <label>{{ translation.translate("frequentlyRepeated", "Klient") }}</label>
                    <span class="required-star">*</span>
                    <b-form-input ref="inputName"
                                  :value="$v.Client.Name.$model"
                                  v-model="Client.Name"
                                  trim
                                  type="text"
                                  :maxlength="200"
                                  :class="{ 'is-invalid': $v.Client.Name.$error }"
                                  autocomplete="off"
                    >
                    </b-form-input>
                    <div class="text-danger" v-if=" $v.Client.Name.$error">
                        <div class="invalid-feedback d-block" v-if="!$v.Client.Name.required">
                            {{ translation.translate("frequentlyRepeated", "vvediteImyaKlienta") }}
                        </div>
                        <div class="invalid-feedback d-block" v-if="!$v.Client.Name.maxLength">
                            {{ translation.translate("frequentlyRepeated", "dlinaNazvaniya") }}
                        </div>
                    </div>
                </b-form-group>

                <b-form-group class="my-2">
                    <label>{{ translation.translate("frequentlyRepeated", "informatsiya") }}</label>
                    <b-form-textarea v-model="Client.Details" trim></b-form-textarea>
                </b-form-group>

                <b-form-group class="my-2">
                    <label>{{ translation.translate("frequentlyRepeated", "ProcentLoyalnosti") }}</label>
                    <b-form-input v-model="Client.BonusPercent" trim></b-form-input>
                </b-form-group>
                <b-form-group :label='translation.translate("login","nomerTelefona")'
                              class="mt-4 my-2 has-float-label mb-4 phone-field"
                              v-if="!Client.ClientId && $store.getters.isAllowedAddClientContact"
                >
                    <div class="input-group">
                        <div class="input-group-prepend"><span
                            :class="{ 'invalid-phone': invalidNumber }"
                            class="input-group-text">+998</span></div>
                        <the-mask v-model="Client.PhoneNumber" type="tel"
                                  ref="phoneNumber"
                                  :class="{ 'invalid-phone': invalidNumber }"
                                  mask="## ### ## ##" placeholder="-- --- -- --"/>
                    </div>
                    <small class="text-danger" v-if="invalidNumber">
                        {{ translation.translate("frequentlyRepeated", "nepravilniyFormat") }}
                    </small>
                </b-form-group>

                <b-button-toolbar class="float-right mt-3">
                    <b-button-group class="mx-1">
                        <b-button @click="() => $emit('cancel')" type="button">
                            {{ translation.translate("frequentlyRepeated", "zakryt") }}
                        </b-button>
                    </b-button-group>
                    <b-button-group class="mx-1">
                        <b-button
                            :disabled="isSubmitted"
                            type="submit"
                            class="shadow-button"
                            variant="primary"
                        >
                            <b-spinner v-if="isSubmitted" small></b-spinner>
                            {{
                                Client.ClientId ? translation.translate("frequentlyRepeated", "soxranit") : translation.translate("frequentlyRepeated", "dobavit")
                            }}
                        </b-button>
                    </b-button-group>
                </b-button-toolbar>
            </b-form>
        </b-row>
    </b-container>
</template>

<script>
import {validationMixin} from "vuelidate";
import translation from '../../translation/translate';
import {required, maxLength, minLength} from "vuelidate/lib/validators";
import ClientService from '../../services/client/client.service';
import ClientContactService from "../../services/client/client.contact.service";
import {VueTheMask} from "vue-the-mask";

export default {
    name: "ClientForm",
    components: {TheMask: VueTheMask},
    mixins: [validationMixin],
    data() {
        return {
            Client: {
                Name: "",
                Details: null,
                BonusPercent: 0,
                ClientId: null,
                PhoneNumber: "",
            },
            isSubmitted: false,
            translation: translation,
            invalidNumber: false,
        };
    },
    validations: {
        Client: {
            Name: {
                required,
                maxLength: maxLength(200)
            },
            PhoneNumber: {
                minLength: minLength(9)
            },
        },
    },

    methods: {
        onSubmit(event) {
            let vm = this;
            event.preventDefault();
            vm.$v.Client.$touch();
            this.invalidNumber = !!(this.Client.PhoneNumber && this.Client.PhoneNumber.length !== 9);
            if (!vm.$v.Client.$invalid) {
                vm.isSubmitted = true;
                if (vm.Client.ClientId) {
                    let data = {
                        ClientId: vm.Client.ClientId,
                        Name: vm.Client.Name,
                        Details: vm.Client.Details,
                        BonusPercent: vm.Client.BonusPercent
                    };
                    ClientService.edit(data).then((response) => {
                        vm.onReset();
                        vm.isSubmitted = false;
                        vm.$emit('updated', response);
                        vm.$notify("success", 'Успешно', `Клиент ${response.Name} успешно изменён`, {
                            duration: 4000,
                            permanent: false
                        });
                    }).catch((error) => {
                        vm.$notify("error", "Возникла ошибка", error.message, {
                            duration: 3000,
                            permanent: false
                        });
                    })
                } else {
                    let data = {
                        Name: vm.Client.Name,
                        Details: vm.Client.Details,
                        BonusPercent: vm.Client.BonusPercent
                    };
                    ClientService.create(data).then((response) => {
                        vm.onReset();
                        vm.addClientContactNumber(response);
                        vm.isSubmitted = false;
                        vm.$emit('updated', response);
                        vm.$notify("success", 'Успешно', `Клиент ${response.Name} успешно добавлен`, {
                            duration: 4000,
                            permanent: false
                        });
                    }).catch((error) => {
                        vm.$notify("error", "Возникла ошибка", error.message, {
                            duration: 3000,
                            permanent: false
                        });
                    });
                }
            }
        },
        addClientContactNumber(response) {
            let vm = this;
            if (vm.Client.PhoneNumber) {
                ClientContactService.create({
                    ClientId: response.ClientId,
                    PhoneNumber: '998' + vm.Client.PhoneNumber
                }).then((res) => {
                }).catch((error) => {
                    vm.$notify("error", "Возникла ошибка", error.message, {
                        duration: 3000,
                        permanent: false
                    });
                });
            }
        },
        onReset() {
            let vm = this;
            vm.Client.Name = "";
            vm.Client.Details = null;
            vm.Client.BonusPercent = 0;
            vm.$v.Client.$reset();
        },
        setFormName(formName) {
            this.Client.Name = formName;
        },
        set(client) {
            let vm = this;
            vm.Client = {
                Name: client && client.Name || '',
                Details: client.Details,
                BonusPercent: client.BonusPercent,
                ClientId: client.ClientId,
            };
        },
        focusToInput() {
            setTimeout(() => {
                this.$refs.inputName.focus();
            }, 50)
        },
    },
}
</script>

<style lang="scss">
.form-group {
    margin: 0;
}

.phone-field input {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-bottom: 0;
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: grey;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid grey;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding-bottom: 2px;
}

.phone-field .input-group .input-group-prepend .input-group-text {
    background-color: transparent;
    border: 1px solid grey;
    border-right: none;
    padding-right: 1px;
    font-size: 15px;
}

.phone-field .input-group input {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 6px;
}

.phone-field .input-group input:focus {
    outline: none;
}
</style>
