export default {
    multiply(a, b) {
        a = a ? +a : 0;
        b = b ? +b : 0;

        let aStr = a.toString().replace(",", ".");
        let aDotIndex = aStr.indexOf(".");
        let aExp = 1;
        if (aDotIndex > -1) {
            let afterDotDigitsCount = aStr.substr(aDotIndex + 1).length;
            for (let i = 0; i < afterDotDigitsCount; i++)
                aExp *= 10;
        }

        let bStr = b.toString().replace(",", ".");
        let bDotIndex = bStr.indexOf(".");
        let bExp = 1;
        if (bDotIndex > -1) {
            let afterDotDigitsCount = bStr.substr(bDotIndex + 1).length;
            for (let i = 0; i < afterDotDigitsCount; i++)
                bExp *= 10;
        }

        a = parseInt(aStr.replace(".", ''));
        b = parseInt(bStr.replace('.', ''));

        // let result = ((a * aExp) * (b * bExp)) / (aExp *  bExp);
        let result = (a * b) / (aExp * bExp);

        if (typeof (result) === "number")
            return +result;
        return 0;
    },
}
