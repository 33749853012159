import BaseService from "../base/base.service";
import axios from 'axios';
import {map} from "underscore";

class SaleItemService extends BaseService {
    get modelName() {
        return "SaleItem"
    }

    create({sale, saleItem}) {
        return axios.post(this.getCreateUrl(), {
            SaleId: sale.SaleId,
            EmployeeId: sale.Employee ? sale.Employee.EmployeeId : null,
            ItemId: saleItem.Item.Item.ItemId,
            Quantity: +saleItem.Quantity,
            DiscountPercent: saleItem.DiscountPercent || 0,
            HasPackQuantity: saleItem.HasPackQuantity,
            StatePartOperations: map(saleItem.StatePartOperations, (operation)=>{
                return {
                    Id: operation.Id,
                    Name: operation.Name,
                    Quantity: operation.Quantity,
                }
            }),
            CurrencyValue: {
                Value: saleItem.PriceCurrencyValue.Value,
                Rate: saleItem.PriceCurrencyValue.Rate,
                CurrencyId: saleItem.PriceCurrencyValue.CurrencyId
            },
            DiscountCurrencyValue: {
                Value: saleItem.DiscountCurrencyValue.Value,
                Rate: saleItem.DiscountCurrencyValue.Rate,
                CurrencyId: saleItem.DiscountCurrencyValue.CurrencyId,
            },
            CustomSelects: saleItem.CustomSelects || [],
            Marks: saleItem.Marks
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id));
    }

    edit(saleItemId, {sale, saleItem}) {
        return axios.put(this.getEditUrl(saleItemId), {
            SaleId: sale.SaleId,
            EmployeeId: sale.Employee ? sale.Employee.EmployeeId : null,
            ItemId: saleItem.WarehouseItem.Item.ItemId,
            Quantity: +saleItem.Quantity,
            HasPackQuantity: saleItem.HasPackQuantity,
            StatePartOperations: saleItem.StatePartOperations || [],
            CurrencyValue: {
                Value: saleItem.PriceCurrencyValue.Value,
                Rate: saleItem.PriceCurrencyValue.Rate,
                CurrencyId: saleItem.PriceCurrencyValue.CurrencyId
            },
            DiscountCurrencyValue: {
                Value: saleItem.DiscountCurrencyValue.Value,
                Rate: saleItem.DiscountCurrencyValue.Rate,
                CurrencyId: saleItem.DiscountCurrencyValue.CurrencyId,
            },
            DiscountPercent: saleItem.DiscountPercent ? saleItem.DiscountPercent : 0,
            CustomSelects: saleItem.CustomSelects || []
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error)
        })
    }
}

export default new SaleItemService()
