import BaseService from "../base/base.service";
import axios from "axios";

class CompleteSaleService extends BaseService {
    get modelName() {
        return "CompleteSale";
    }

    edit(SaleId, {
        EmployeeId,
        Description,
        SaleNumber,
        ClientPaymentId,
        DiscountCurrencyValue,
        ClientSaleBonusUseCurrencyValue,
        ClientSaleBonusCurrencyValue,
        RegistrationType,
    }) {
        return axios.put(this.getEditUrl(SaleId), {
            DiscountCurrencyValue: {
                Value: DiscountCurrencyValue.Value,
                Rate: DiscountCurrencyValue.Rate,
                CurrencyId: DiscountCurrencyValue.CurrencyId,
            },
            ClientSaleBonusUseCurrencyValue: {
                Value: ClientSaleBonusUseCurrencyValue.Value,
                Rate: ClientSaleBonusUseCurrencyValue.Rate,
                CurrencyId: ClientSaleBonusUseCurrencyValue.CurrencyId,
            },
            ClientSaleBonusCurrencyValue: {
                Value: ClientSaleBonusCurrencyValue.Value,
                Rate: ClientSaleBonusCurrencyValue.Rate,
                CurrencyId: ClientSaleBonusCurrencyValue.CurrencyId,
            },
            Description: Description,
            EmployeeId: EmployeeId ? EmployeeId : null,
            SaleNumber: SaleNumber ? SaleNumber : null,
            ClientPaymentId: ClientPaymentId ? ClientPaymentId : null,
            RegistrationType: RegistrationType,
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }
}

export default new CompleteSaleService();
